import React, { useState, useEffect, useContext } from "react";
import "./list.scss";
import Axios from "axios";
import { API_URL } from "utils/settings";
import CIcon from "@coreui/icons-react";
import { CFormInput, CSpinner, CTooltip } from "@coreui/react";
import SearchIcon from "../../assets/images/search.svg";

import {
  cilDelete,
  cilCommentSquare,
  cibTelegramPlane,
  cilPaperPlane,
  cilUser,
  cilX,
} from "@coreui/icons";
import { useTranslation } from "react-i18next";
import ServiceItem, { ServiceItemLight } from "./ServiceItem";
import { formatBalance, useLocalStorage } from "utils/utils";
import Icon1 from "assets/images/telegram1.png";
import Icon2 from "assets/images/message1.png";
import User1 from "assets/images/user1.png";
import classNames from "classnames";

import OrderIcon from "assets/images/orderIcon.svg";
import LogoutIcon from "assets/images/logoutIcon.svg";
import BalanceIcon from "assets/images/balanceIcon.svg";
import TabsAll from "assets/images/tabs_all.svg";
import TabsStar from "assets/images/tabs_star.svg";
import Ava from "assets/images/avaIcon.png";
import { UserContext } from "core/contexts";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Preloader from "components/Preloader";
import ToastComponent from "components/ToastComponent";

function ServicesList(props) {
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const authorized = userContext.authorized;
  const {
    showAuthModal,
    setShowAuthModal,
    showRegisterModal,
    setShowRegisterModal,
    showForgotModal,
    setShowForgotModal,
    setNeedRedirect,
  } = props;
  const { getOrders, page, perPage, filters, updateUser } = props;
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState("");
  const [topActive, setTopActive] = useState(false);
  const [preProducts, setPreProducts] = useState(null);
  const [defaultProducts, setDefaultProducts] = useState(null);
  const [services, setServices] = useState(null);
  const [defaultServices, setDefaultServices] = useState(null);
  const [search, setSearch] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [favoriteServices, setFavoriteServices] = useLocalStorage(
    "favoriteServices",
    null
  );
  const [showList, setShowList] = useLocalStorage("showList", true);
  useEffect(() => {
    if (!localStorage.getItem("showList")) {
      setShowList(true);
    }
  }, []);
  const { t } = useTranslation();
  const handleChange = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    if (search?.length > 1) {
      setServices(
        defaultServices.filter((el) => {
          let data = search.toLowerCase()?.trim();
          if (
            el.service?.toLowerCase()?.includes(data) ||
            el.serviceName?.toLowerCase()?.includes(data) ||
            el.serviceAliases?.toLowerCase()?.includes(data)
          ) {
            return el;
          }
        })
      );
    } else {
      setServices(defaultServices);
    }
  }, [search]);
  const handleTop = (id) => {
    setLoading(true);
    Axios.get(`${API_URL}/order/stats`, {
      params: {
        service: id,
      },
    }).then(function (response) {
      setLoading(false);
      if (response.data.data?.length > 0) {
        setTopActive(true);
        setServices(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
        setDefaultProducts(
          preProducts.filter(
            (p) =>
              response.data.data.filter((it) => it.country == p.productId)?.[0]
          )
        );
      }
    });
  };
  const getFavorites = (callback = null) => {
    Axios.get(`${API_URL}/favorite/products`)
      .then(function (response) {
        if (response.data.data) {
          const items = response.data.data;
          setFavorites([]);
          setFavorites(items);
          if (callback) {
            callback();
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (callback) {
          callback();
        }
      });
  };
  const getServices = () => {
    setLoading(true);
    Axios.get(`${API_URL}/service/list`)
      .then(function (response) {
        setLoading(false);
        if (response.data.data) {
          const items = response.data.data;
          if (favoriteServices) {
            const data = JSON.parse(favoriteServices);
            const result = items?.sort(function (a, b) {
              const aIndex = data.indexOf(a.service);
              const bIndex = data.indexOf(b.service);
              if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
              }
              if (aIndex !== -1) {
                return -1;
              }
              if (bIndex !== -1) {
                return 1;
              }
              return 0;
            });
            setServices(result);
            setDefaultServices(result);
          } else {
            setServices(items);
            setDefaultServices(items);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getServices();
    getFavorites();
  }, []);
  return (
    <div
      className={`services-list ${
        authorized ? "authorized" : "non-authorized"
      }`}
    >
      <div className="services-list__header">
        {authorized ? (
          <div className="services__auth-block">
            <img src={Ava} alt="" />
            {user?.email && (
              <CTooltip content={<>{user?.email}</>}>
                <div
                  className="service__auth-mail"
                  onClick={() => {
                    navigator.clipboard.writeText(user.email);
                    ToastComponent("success", t("copy.email"));
                  }}
                >
                  <span className="service__auth-mail-name">
                    {user?.email?.split("@")[0]}
                  </span>
                  <span className="service__auth-mail-domen">
                    @{user?.email?.split("@")[1]}
                  </span>
                </div>
              </CTooltip>
            )}
            {user?.balance && (
              <div className="service__auth-balance">
                {formatBalance(user.balance || 0)}
                &nbsp;(
                <span
                  style={{
                    color: "#8161EB",
                    transform: "translateY(4px)",
                    display: "inline-block",
                  }}
                >
                  &nbsp;*&nbsp;
                </span>
                {formatBalance(user.frozenBalance || 0)})
              </div>
            )}
            <div className="service__auth-buttons">
              {user?.isAdmin && (
                <Link
                  to={"/admin/stats/users"}
                  className="service__auth-button_order"
                >
                  <img src={OrderIcon} alt="" />
                </Link>
              )}
              <Link
                to={"/panel/balance"}
                className="service__auth-button_balance"
              >
                <img src={BalanceIcon} alt="" />
              </Link>
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.href = "/";
                }}
                className="service__auth-button_logout"
              >
                <img src={LogoutIcon} alt="" />
              </button>
            </div>
          </div>
        ) : (
          <button
            className="services__auth"
            onClick={() => setShowAuthModal(true)}
          >
            {/* <img src={User1} alt="" /> */}
            {/* <CIcon icon={cilUser} /> */}
            <svg
              fill="#8161eb"
              width="17px"
              height="17px"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#8161eb"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M15.992 2c3.396 0 6.998 2.86 6.998 4.995v4.997c0 1.924-0.8 5.604-2.945 7.293-0.547 0.43-0.831 1.115-0.749 1.807 0.082 0.692 0.518 1.291 1.151 1.582l8.703 4.127c0.068 0.031 0.834 0.16 0.834 1.23l0.001 1.952-27.984 0.002v-2.029c0-0.795 0.596-1.045 0.835-1.154l8.782-4.145c0.63-0.289 1.065-0.885 1.149-1.573s-0.193-1.37-0.733-1.803c-2.078-1.668-3.046-5.335-3.046-7.287v-4.997c0.001-2.089 3.638-4.995 7.004-4.995zM15.992-0c-4.416 0-9.004 3.686-9.004 6.996v4.997c0 2.184 0.997 6.601 3.793 8.847l-8.783 4.145s-1.998 0.89-1.998 1.999v3.001c0 1.105 0.895 1.999 1.998 1.999h27.986c1.105 0 1.999-0.895 1.999-1.999v-3.001c0-1.175-1.999-1.999-1.999-1.999l-8.703-4.127c2.77-2.18 3.708-6.464 3.708-8.865v-4.997c0-3.31-4.582-6.995-8.998-6.995v0z"></path>{" "}
              </g>
            </svg>
            {t("common.auth_for")}
          </button>
        )}
        <div className="services__buttons">
          <a
            className="primary"
            href="https://t.me/sms_online_pro_Support_Bot"
            target="_blank"
          >
            {/* <img src={Icon1} alt="" /> */}
            <CIcon icon={cilCommentSquare} />
            {t("common.us")}
          </a>
          <a
            className="primary"
            href="https://t.me/smsonlinepro"
            target="_blank"
          >
            {/* <img src={Icon2} alt="" /> */}
            <CIcon icon={cilPaperPlane} />
            {t("common.tg")}
          </a>
          <button
            className="primary show-list"
            onClick={() => setShowList(!showList)}
          >
            {showList ? t("common.hide_list") : t("common.show_list")}
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: showList ? "rotate(180deg)" : "unset" }}
            >
              <path
                d="M0.884 1.892L2.216 0.547999L6.56 4.964L10.904 0.547999L12.236 1.892L6.56 7.568L0.884 1.892Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      {authorized && (
        <div
          className={`services__tabs ${
            activeTab == 0 ? "services__tabs_all" : "services__tabs_favorites"
          }`}
        >
          <div className="services__tabs-item" onClick={() => setActiveTab(0)}>
            <img src={TabsAll} alt="" />
            {t("tabs.all_items")}
          </div>
          <div className="services__tabs-item" onClick={() => setActiveTab(1)}>
            <img src={TabsStar} alt="" />
            {t("tabs.favorites")}
            {favorites?.length > 0 && (
              <span className="services__tabs-badge">{favorites.length}</span>
            )}
          </div>
        </div>
      )}
      <div
        className={classNames({
          "services__header-wrapper": true,
          hiddenServices: !showList,
        })}
      >
        {activeTab == 0 && (
          <div className="services__search">
            <img src={SearchIcon} className="services__search-icon" alt="" />
            <CFormInput
              placeholder={t("search.services")}
              value={search}
              onChange={(e) => handleChange(e.target.value)}
            />
            {search?.length > 1 ? (
              <CIcon icon={cilX} onClick={() => setSearch("")} />
            ) : (
              <></>
            )}
          </div>
        )}
        <div
          className={`services__header ${
            activeTab == 0 ? "" : "services__header_light"
          }`}
        >
          <div className="services__header-item">{t("table.service")}</div>
          <div className="services__header-item">{t("table.price")}</div>
        </div>
      </div>
      {/* <div className="services__header-wrapper">
        {activeTab == 0 && (
          <div className="services__search">
            <img src={SearchIcon} className="services__search-icon" alt="" />
            <CFormInput
              placeholder={t("search.services")}
              value={search}
              onChange={(e) => handleChange(e.target.value)}
            />
            {search?.length > 1 ? (
              <CIcon icon={cilX} onClick={() => setSearch("")} />
            ) : (
              <></>
            )}
          </div>
        )}
     
      </div> */}

      <div
        className={classNames({
          "services-list-wrapper": true,
          hiddenServices: !showList,
        })}
        style={{ position: "relative" }}
      >
        {loading && (
          <div className="with-preloader">
            <Preloader />
          </div>
        )}
        <div style={{ display: activeTab == 0 ? "block" : "none" }}>
          {services?.length > 0
            ? services?.map((el, i) => {
                return (
                  <ServiceItem
                    el={el}
                    index={i}
                    favoriteServices={favoriteServices}
                    setFavoriteServices={setFavoriteServices}
                    getOrders={getOrders}
                    page={page}
                    perPage={perPage}
                    filters={filters}
                    updateUser={updateUser}
                    getFavorites={getFavorites}
                    favorites={favorites}
                    show={show}
                    setShow={setShow}
                    key={el.service}
                    authorized={authorized}
                    setShowAuthModal={setShowAuthModal}
                    setNeedRedirect={setNeedRedirect}
                  />
                );
              })
            : !loading && (
                <div className="services__not-found">
                  {t("table.not_found")}
                </div>
              )}
        </div>
        <div style={{ display: activeTab == 1 ? "block" : "none" }}>
          {favorites?.length > 0
            ? favorites?.map((el, i) => {
                return (
                  <ServiceItemLight
                    el={el}
                    index={i}
                    favoriteServices={favoriteServices}
                    setFavoriteServices={setFavoriteServices}
                    getOrders={getOrders}
                    page={page}
                    perPage={perPage}
                    filters={filters}
                    updateUser={updateUser}
                    getFavorites={getFavorites}
                    favorites={favorites}
                    show={show}
                    setShow={setShow}
                    key={el.service}
                    authorized={authorized}
                    setShowAuthModal={setShowAuthModal}
                    setNeedRedirect={setNeedRedirect}
                  />
                );
              })
            : !loading && (
                <div className="services__not-found">
                  {t("table.not_found")}
                </div>
              )}
        </div>
      </div>
    </div>
  );
}

export default ServicesList;
