import React, { useState, useRef } from "react";
import "./index.scss";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.svg";
import UserIcon from "../../assets/images/userForgot.svg";
import PasswordIcon from "../../assets/images/passwordAuth.svg";
import Axios from "axios";
import { API_URL } from "../../utils/settings";
import { toast } from "react-toastify";
import PasswordValidation from "../PasswordValidation";
import ToastComponent from "components/ToastComponent";
import classNames from "classnames";
import { Turnstile } from "@marsidev/react-turnstile";

function ForgotPasswordModal(props) {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const [token, setToken] = useState();
  const ref = React.useRef();
  const { show, setShow, callback } = props;

  const submit = (e) => {
    e.preventDefault();
    if (token) {
      forgot(token);
    } else {
      ToastComponent("error", t("captcha.notice"));
    }
  };

  const forgot = (token) => {
    fetch(`${API_URL}/password/forgot`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: user,
        cfTurnstile: token,
      }),
    })
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = new Error(`HTTP error! Status: ${response.status}`);
          error.response = data;
          throw error;
        }

        return data;
      })
      .then(() => {
        toast.info(t("common.forgot_info"));
        setToken(null);
        ref.current.reset();
      })
      .catch((error) => {
        setToken(null);
        ref.current.reset();

        if (error.response) {
          const errors = error.response.errors;
          if (errors) {
            errors.forEach((el) => {
              if (el.detail.includes('"') || el.detail.includes(":")) {
                ToastComponent("error", new String(el.detail));
              } else {
                ToastComponent("error", t(el.detail));
              }
            });
          }
        } else {
          toast.info(t("common.forgot_info"));
        }
      });

  };
  return (
    <CModal
      visible={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>{t("common.password_forgot")} </CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>

      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          <div className="modal-auth-form">
            <InputGroup className="mb-3">
              <InputGroupText id="user">
                <img src={UserIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.username_forgot")}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                required
                type="email"
                name={"regUser"}
                value={user}
              />
            </InputGroup>
          </div>
          <div className="captcha-block">
            <Turnstile
              ref={ref}
              siteKey="0x4AAAAAAA1jAYEjgl2Gpefx"
              onSuccess={(token) => setToken(token)}
              options={{
                theme: "light",
                size: "flexible",
              }}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <button
            className={classNames({
              primary: true,
              disabled: !token,
            })}
            onClick={() => {
              // history.replace({
              //     search: "",
              // });
              // setShow(false);
            }}
          >
            {t("common.restore")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default ForgotPasswordModal;
