import React, { useState, useRef } from "react";
import "./index.scss";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInputGroup as InputGroup,
  CInputGroupText as InputGroupText,
  CFormInput as Input,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import ModalCloseIcon from "../../assets/images/modal-close.svg";
import Logo from "../../assets/images/logo-sms.svg";
import UserIcon from "../../assets/images/userAuth.svg";
import PasswordIcon from "../../assets/images/passwordAuth.svg";
import Axios from "axios";
import { API_URL } from "../../utils/settings";
import { toast } from "react-toastify";
import ToastComponent from "components/ToastComponent";
import { Turnstile } from "@marsidev/react-turnstile";
import classNames from "classnames";

function AuthModal(props) {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const ref = React.useRef();
  const [token, setToken] = useState();
  const [show2fa, setShow2fa] = useState(false);
  const [password, setPassword] = useState("");
  const {
    show,
    setShow,
    callback,
    setShowRegisterModal,
    setShowForgotModal,
    needRedirect,
    setShow2faModal,
  } = props;
  const submit = (e) => {
    e.preventDefault();
    if (token) {
      auth(token);
    } else {
      ToastComponent("error", t("captcha.notice"));
    }
  };
  const auth = (token) => {
    fetch(`${API_URL}/auth/login_check`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: user,
        password: password,
        cfTurnstile: token,
      }),
    })
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = new Error(`HTTP error! Status: ${response.status}`);
          error.response = data;
          throw error;
        }

        return data;
      })
      .then((response) => {
        if (response?.emailConfirmation === false) {
          ToastComponent("info", t("register.login_notify"));
          return;
        }
        if (response?.token) {
          localStorage.setItem("token", response.token);
          setShow(false);
          window.location.href = "/panel/main";
        } else if (
          response?.login == "success" &&
          response?.twoFactorComplete === false
        ) {
          setShow(false);
          setShow2faModal(true);
          setShow2fa(true);
        }
        setToken(null);
        ref.current.reset();
      })
      .catch((error) => {
        setToken(null);
        ref.current.reset();
        if (error.response.message) {
          if (
            error.response.message.includes('"') ||
            error.response.message.includes(":")
          ) {
            ToastComponent("error", new String(error.response.message));
          } else {
            ToastComponent("error", t(error.response.message));
          }
        }
        if (error.response) {
          const errors = error.response.errors;
          if (errors) {
            errors.forEach((el) => {
              if (el.detail.includes('"') || el.detail.includes(":")) {
                ToastComponent("error", new String(el.detail));
              } else {
                ToastComponent("error", t(el.detail));
              }
            });
          }
        } else {
          toast.info(t("register.notify"));
        }
      });
  };
  return (
    <CModal
      visible={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <CModalHeader>
        <img src={Logo} className="modal-logo" alt="" />
        <CModalTitle>
          {t("heading.auth")}{" "}
          <span
            className="modal-link"
            onClick={() => {
              setShow(false);
              setShowRegisterModal(true);
            }}
          >
            {t("front_page.body.register")}
          </span>
        </CModalTitle>
        <div className="modal-close" onClick={() => setShow(false)}>
          <img src={ModalCloseIcon} alt="" />
        </div>
      </CModalHeader>
      <form onSubmit={(e) => submit(e)}>
        <CModalBody>
          <div className="modal-auth-form">
            <InputGroup className="mb-3">
              <InputGroupText id="user">
                <img src={UserIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.username")}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                name="user"
                required
                type="email"
                autoComplete
                value={user}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupText id="user">
                <img src={PasswordIcon} alt="" />
              </InputGroupText>
              <Input
                placeholder={t("common.password")}
                onChange={(e) => {
                  setPassword(e.target.value);
                  // if (loginMailNotify) {
                  //     showLoginMailNotify(false);
                  // }
                }}
                value={password}
                name="password"
                required
                type="password"
                autoComplete
              />
            </InputGroup>
          </div>
          <div className="captcha-block">
            <Turnstile
              siteKey="0x4AAAAAAA1jAYEjgl2Gpefx"
              onSuccess={(token) => setToken(token)}
              options={{
                theme: "light",
                size: "flexible",
              }}
              ref={ref}
            />
          </div>
        </CModalBody>

        <CModalFooter>
          <span
            className="modal-link"
            onClick={() => {
              setShow(false);
              setShowForgotModal(true);
            }}
          >
            {t("common.forgot")}
          </span>
          <button
            className={classNames({
              primary: true,
              disabled: !token,
            })}
            onClick={() => {
              // history.replace({
              //     search: "",
              // });
              // setShow(false);
            }}
          >
            {t("heading.auth_action")}
          </button>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default AuthModal;
