import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../assets/images/logo-sms.svg";
import CIcon from "@coreui/icons-react";
import { cibTelegramPlane, cilContact, cilSettings } from "@coreui/icons";
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <Link to="/rules">{t("nav.rules")}</Link>
          <div className="copyright">{t("copyright")}</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
