import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import Logo from "../../assets/images/logo-sms.svg";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CTooltip,
} from "@coreui/react";
import ToggleIcon from "../../assets/images/toggle.svg";
import { formatBalance, useLocalStorage } from "utils/utils";
import { useTranslation } from "react-i18next";
import Ava from "assets/images/avaIcon.png";
import { UserContext } from "core/contexts";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LogoutIcon from "assets/images/logoutIcon.svg";
import ReturnIcon from "assets/images/return.svg";
import classNames from "classnames";

import { icons } from "utils/icons";
import ToastComponent from "components/ToastComponent";
function AdminSidebar(props) {
  const [locale, setLocale] = useLocalStorage("locale", "gb");
  const [expanded, setExpanded] = useLocalStorage("expanded", "false");
  const [expandedValue, setExpandedValue] = useState(false);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();
  const [showDD, setShowDD] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const locales = {
    ru: "RU",
    gb: "EN",
    es: "ES",
    cn: "CN",
  };
  const adminMenu = [
    {
      label: "heading.users",
      link: "/admin/stats/users",
      tags: "users",
    },
    {
      label: "heading.partners",
      link: "/admin/partners",
    },
    {
      label: "menu.stats",
      dropdown: [
        {
          label: "heading.orders",
          link: "/admin/stats/orders",
        },
        {
          label: "heading.payments",
          link: "/admin/stats/payments",
        },
      ],
    },
    {
      label: "menu.coupons",
      link: "/admin/coupons",
    },
    {
      label: "menu.admin_settings",
      link: "/admin/settings/",
    },
    {
      label: "menu.logs",
      link: "/admin/logs",
    },
    {
      label: "nav.rules",
      link: "/admin/rules/",
    },
    // {
    //   label: "common.logout",
    //   type: "logout",
    // },
  ];

  return (
    <aside className={`aside ${!expanded ? "expanded" : ""} `}>
      <div className="aside-brand">
        <div className="aside-logo">
          <img src={Logo} alt="" />
        </div>
        <CDropdown visible={isVisible}>
          <CDropdownToggle
            trigger="click"
            // variant="secondary"
            onClick={() => setVisible(!isVisible)}
          >
            <div className="d-flex align-items-center">
              <div class={`flag flag-${locale}`} />
              <span>{locales[locale]}</span>
            </div>
            <img src={ToggleIcon} alt="" />
          </CDropdownToggle>
          <CDropdownMenu>
            {Object.entries(locales).map((el) => {
              return (
                <CDropdownItem
                  // href="#"
                  onClick={() => {
                    setLocale(el[0]);
                    setVisible(false);
                  }}
                >
                  <div class={`flag flag-${el[0]}`} />
                  {el[1]}
                </CDropdownItem>
              );
            })}
          </CDropdownMenu>
        </CDropdown>
      </div>
      <div className="aside__content">
        <div className="aside__inner">
          <div className="aside__toggle" onClick={() => setExpanded(!expanded)}>
            <div className="hamburger-lines">
              <span className="line line1" />
              <span className="line line2" />
              <span className="line line3" />
            </div>
            <p>{t("common.hide_menu")}</p>
          </div>
          <div className="aside__user">
            <div className="services__auth-block">
              <img src={Ava} alt="" />
              {user?.email && (
                <CTooltip content={<>{user?.email}</>}>
                  <div
                    className="service__auth-mail"
                    onClick={() => {
                      navigator.clipboard.writeText(user.email);
                      ToastComponent("success", t("copy.email"));
                    }}
                  >
                    <span className="service__auth-mail-name">
                      {user?.email?.split("@")[0]}
                    </span>
                    <span className="service__auth-mail-domen">
                      @{user?.email?.split("@")[1]}
                    </span>
                  </div>
                </CTooltip>
              )}
              {user?.balance && (
                <div className="service__auth-balance">
                  {formatBalance(user.balance || 0)}
                  &nbsp;(
                  <span
                    style={{
                      color: "#8161EB",
                      transform: "translateY(4px)",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;*&nbsp;
                  </span>
                  {formatBalance(user.frozenBalance || 0)})
                </div>
              )}
            </div>
            <div
              className="aside__return"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/";
              }}
            >
              <img src={LogoutIcon} alt="" />
              <span>{t("common.go_to_panel")}</span>
            </div>
          </div>
          <div
            className="aside__show-menu"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <span>{t("common.side_show_menu")}</span>
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.884 1.892L2.216 0.547999L6.56 4.964L10.904 0.547999L12.236 1.892L6.56 7.568L0.884 1.892Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={`aside__menu ${showMobileMenu ? "show" : ""}`}>
            {adminMenu.map((el) => {
              if (el?.dropdown?.length > 0) {
                return (
                  <div
                    className={classNames({
                      "aside__menu-children": true,
                      active:
                        el.dropdown.filter(
                          (nav) => nav.link == location.pathname
                        )?.length > 0 || showDD,
                      colored:
                        el.dropdown.filter(
                          (nav) => nav.link == location.pathname
                        )?.length > 0,
                    })}
                  >
                    <p
                      onClick={() => setShowDD(!showDD)}
                      className="aside__menu-item"
                    >
                      <div className="aside__menu-item-image">
                        <img src={icons[el.label]} alt="" />
                      </div>
                      <span>{t(el.label)}</span>
                    </p>
                    <div className="aside__menu-children-items">
                      {el.dropdown.map((d) => {
                        return (
                          <Link
                            to={d.link}
                            className={classNames({
                              active:
                                location.pathname.includes(d.link) ||
                                location.pathname == d.link,
                              "aside__menu-item": true,
                            })}
                          >
                            <div className="aside__menu-item-image">
                              <img src={icons[d.label]} alt="" />
                            </div>
                            <span>{t(d.label)}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return (
                <Link
                  to={el.link}
                  className={classNames({
                    active:
                      location.pathname.includes(el.link) ||
                      location.pathname == el.link ||
                      location.pathname.includes(el.tags),
                    "aside__menu-item": true,
                  })}
                >
                  <div className="aside__menu-item-image">
                    <img src={icons[el.label]} alt="" />
                  </div>
                  <span>{t(el.label)}</span>
                </Link>
              );
            })}
            <Link
              to={"/panel/main"}
              className={classNames({
                "aside__menu-item": true,
                "aside__menu-item_return": true,
              })}
            >
              <div className="aside__menu-item-image">
                <img src={ReturnIcon} alt="" />
              </div>
              <span>{t("common.return_to_shop")}</span>
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default AdminSidebar;
