import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
// import "./index.dark.scss";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormSelect,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { cilAccountLogout } from "@coreui/icons";
import Day from "assets/images/day.svg";
import Night from "assets/images/night.svg";
import classNames from "classnames";
import { useLocalStorage, useOnClickOutside } from "utils/utils";
import { withTranslation } from "react-i18next";
import Logo from "../../assets/images/logo-sms.svg";
import { useTranslation } from "react-i18next";
import ToggleIcon from "../../assets/images/toggle.svg";
import AuthModal from "../AuthModal/auth";
import RegisterModal from "../AuthModal/register";
import ForgotPasswordModal from "../AuthModal/password";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ResetPasswordModal from "components/AuthModal/resetPassword";
import Auth2faModal from "components/AuthModal/auth2fa";
import { UserContext } from "core/contexts";

function Header(props) {
  const userContext = useContext(UserContext);
  const ordersCount = userContext.ordersCount;
  const { authorized, needRedirect, setNeedRedirect } = props;
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [locale, setLocale] = useLocalStorage("locale", "gb");
  const [isVisible, setVisible] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setVisible(false));

  const [active, setActive] = useState(false);
  const {
    showAuthModal,
    setShowAuthModal,
    showResetModal,
    setShowResetModal,
    showRegisterModal,
    setShowRegisterModal,
    showForgotModal,
    setShowForgotModal,
    show2faModal,
    setShow2faModal,
  } = props;
  const { t } = useTranslation();
  const locales = {
    ru: "RU",
    gb: "EN",
    es: "ES",
    cn: "CN",
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (queryParams.has("auth")) {
      setShowAuthModal(true);
    } else if (queryParams.has("forgot")) {
      setShowForgotModal(true);
    } else if (queryParams.has("register")) {
      setShowRegisterModal(true);
    } else if (queryParams.has("reset_password")) {
      setShowResetModal(true);
    }
  }, []);
  const links = [
    {
      label: t("nav.main"),
      link: "/",
    },
    {
      label: t("nav.work"),
      link: "/#work",
    },
    // {
    //   label: t("nav.services"),
    //   link: "/#services",
    // },
    {
      label: t("nav.faq"),
      link: "/faq/index.html",
      type: "href",
    },
    {
      label: t("nav.api"),
      link: "/docs/index.html",
      type: "href",
    },
    // {
    //   label: t("nav.news"),
    //   link: "/news",
    // },
    {
      label: t("nav.rules"),
      link: "/rules",
    },
    // {
    //   label: t("nav.contacts"),
    //   link: "/contacts",
    // },
    {
      label: t("nav.profile"),
      link: "/panel/main",
      disabled: window.location.href.includes("panel"),
      type: "auth",
    },
  ];
  return (
    <>
      <header className={`header ${active ? "active" : ""}`}>
        <div className={`container ${!authorized ? "nonAuthorized" : ""} `}>
          <div className="d-flex justify-content-between w-100">
            <div className="header__content">
              <img src={Logo} className="header__logo" alt="" />

              <nav className="nav">
                {links.map((el) => {
                  if (el.type == "auth") {
                    if (props.authorized) {
                      return (
                        <Link
                          to={el.link}
                          onClick={() => setActive(false)}
                          className={classNames({
                            "nav-auth": true,
                            fulled: ordersCount > 0,
                          })}
                        >
                          {el.label}
                          {ordersCount > 0 && <span>{ordersCount}</span>}
                        </Link>
                      );
                    } else {
                      return (
                        <span
                          onClick={() => {
                            setShowAuthModal(true);
                            setActive(false);
                          }}
                        >
                          {el.label}
                        </span>
                      );
                    }
                  }
                  if (el.type == "href") {
                    return <a href={el.link}>{el.label}</a>;
                  }
                  return el.link.includes("#") ? (
                    <a href={el.link} onClick={() => setActive(false)}>
                      {el.label}
                    </a>
                  ) : (
                    <Link to={el.link} onClick={() => setActive(false)}>
                      {el.label}
                    </Link>
                  );
                })}
              </nav>
            </div>
            {/* <div className="header__theme">

                    <button
                        className={classNames({
                            "active": theme === 'light',
                        })}
                        onClick={() => {
                            setTheme('light')
                        }}
                    >
                        <img src={Day} alt="" />
                    </button>
                    <span className="header__separator" />
                    <button
                        className={classNames({
                            "active": theme === 'dark',
                        })}
                        onClick={() => {
                            setTheme('dark')
                        }}
                    >
                        <img src={Night} alt="" />
                    </button>


                </div> */}
            <div className="d-flex header__end" style={{ columnGap: "16px" }}>
              <div className="header__language">
                <span>{t("header.language")}</span>
                <CDropdown
                  ref={ref}
                  visible={isVisible}
                  // autoClose={'inside'}
                  // autoClose="inside"
                >
                  <CDropdownToggle
                    trigger="click"
                    // variant="secondary"
                    onClick={() => setVisible(!isVisible)}
                  >
                    <div class={`flag flag-${locale}`} />
                    <span>{locales[locale]}</span>{" "}
                    <img src={ToggleIcon} alt="" />
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {Object.entries(locales).map((el) => {
                      return (
                        <CDropdownItem
                          // href="#"
                          onClick={() => {
                            setLocale(el[0]);
                            setVisible(false);
                          }}
                        >
                          <div class={`flag flag-${el[0]}`} />
                          {el[1]}
                        </CDropdownItem>
                      );
                    })}
                  </CDropdownMenu>
                </CDropdown>
              </div>
              {!authorized && (
                <button
                  className="primary auth-button"
                  onClick={() => setShowAuthModal(true)}
                >
                  {t("heading.auth_action")}
                </button>
              )}
            </div>
            {/* <button
                    className="reset-btn"
                    onClick={() => {
                        localStorage.removeItem('token');
                        window.location = '/auth'
                    }}
                >
                    <CIcon icon={cilAccountLogout} />
                </button> */}
          </div>
        </div>
      </header>
      <div className="mobile-page-header">
        <div className="container">
          <div className="mobile-page-header__content">
            <img src={Logo} className="mobile-page-haeder__logo" alt="" />
            <div
              class={`hamburger-lines ${active ? "active" : ""}`}
              onClick={() => setActive(!active)}
            >
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
          </div>
        </div>
      </div>
      <AuthModal
        show={showAuthModal}
        setShow={setShowAuthModal}
        setShowRegisterModal={setShowRegisterModal}
        setShowForgotModal={setShowForgotModal}
        needRedirect={needRedirect}
        setShow2faModal={setShow2faModal}
        setNeedRedirect={setNeedRedirect}
      />
      <RegisterModal
        show={showRegisterModal}
        setShow={setShowRegisterModal}
        showAuthModal={showAuthModal}
        setShowAuthModal={setShowAuthModal}
      />
      <ForgotPasswordModal
        show={showForgotModal}
        setShow={setShowForgotModal}
      />
      <Auth2faModal show={show2faModal} setShow={setShow2faModal} />
      <ResetPasswordModal
        show={showResetModal}
        setShow={setShowResetModal}
        showAuthModal={showAuthModal}
        setShowAuthModal={setShowAuthModal}
      />
    </>
  );
}

export default withTranslation()(Header);
